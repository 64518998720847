import { Colors } from '@foxtail-dev/user-clients'
import { Box, Divider } from '@mui/material'
import { FoxTypography } from '../../common/FoxTypography'
import { ListingSchema } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/Listing'

type ItemInformationProps = {
    commonDetails: ListingSchema['listingDescription']['listingDetails']['commonDetails']
    inventory: number | null
}

export const ItemInformation = (props: ItemInformationProps) => {
    const { commonDetails, inventory } = props
    return (
        <Box sx={styles.container}>
            <Box sx={styles.containerTitle}>
                <FoxTypography variant='subtitle1'>Item information</FoxTypography>
            </Box>
            <Divider />

            <Box sx={styles.itemInfoRow}>
                <FoxTypography variant='subtitle2' sx={styles.itemRowTitleText}>
                    Price
                </FoxTypography>
                <FoxTypography variant='body1'>${commonDetails?.price}</FoxTypography>
            </Box>
            <Box sx={styles.itemInfoRow}>
                <FoxTypography variant='subtitle2' sx={styles.itemRowTitleText}>
                    Quantity
                </FoxTypography>
                <FoxTypography variant='body1'>{inventory ?? ''}</FoxTypography>
            </Box>
            {commonDetails?.tags && commonDetails?.tags.length > 0 && (
                <Box sx={[styles.tagsInfoRow, { marginTop: '12px', marginBottom: '36px', alignItems: 'flex-start' }]}>
                    <FoxTypography variant='subtitle2' sx={styles.itemRowTitleText}>
                        Tags
                    </FoxTypography>
                    <Box sx={[styles.itemRowDetailsText, { flexDirection: 'row', display: 'flex', flexWrap: 'wrap' }]}>
                        {commonDetails?.tags?.map((tag, i) => {
                            return (
                                <Box sx={styles.containerTag} key={tag}>
                                    <FoxTypography variant='body1'>#{tag}</FoxTypography>
                                </Box>
                            )
                        })}
                    </Box>
                </Box>
            )}
            <Box sx={[styles.itemInfoRowDescription, { marginBottom: '22px' }]}>
                <FoxTypography variant='subtitle2' sx={styles.itemRowTitleText}>
                    Description
                </FoxTypography>
                <FoxTypography variant='body1' sx={styles.itemRowDetailsText}>
                    {commonDetails?.description}
                </FoxTypography>
            </Box>
        </Box>
    )
}

const styles = {
    container: {
        borderWidth: '1px',
        borderColor: Colors.light.divider,
        borderStyle: 'solid',
        borderRadius: '10px',
        marginRight: '19px',
        marginBottom: '28px'
    },
    containerTitle: {
        paddingTop: '23px',
        marginLeft: '22px',
        height: '64px'
    },
    containerTag: {
        backgroundColor: Colors.light.unfocusedInput,
        marginLeft: '8px',
        marginRight: '8px',
        borderRadius: '16px',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: Colors.light.divider,
        marginTop: '4px',
        paddingLeft: '12px',
        paddingRight: '12px',
        paddingTop: '4px',
        paddingBottom: '4px'
    },
    itemInfoRow: {
        height: '48px',
        marginLeft: '20px',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center'
    },
    tagsInfoRow: {
        marginLeft: '20px',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center'
    },
    itemInfoRowDescription: {
        marginLeft: '20px',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'flex-start',
        marginTop: '12px'
    },
    itemRowTitleText: {
        width: '174px'
    },
    itemRowDetailsText: {
        width: '572px',
        paddingRight: '40px'
    }
}
